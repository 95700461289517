import { Component, OnInit, OnChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ContactFormService } from './contact-form.service';
import { Email } from 'src/assets/scripts/smtp.js';
import { combineLatest, forkJoin } from 'rxjs';
import { combineAll } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
declare let Email: any;

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css'],
})
export class ContactFormComponent implements OnInit {
  constructor(
    private contactFormService: ContactFormService,
    private route: ActivatedRoute,
    private router: Router) {}

  ngOnInit(): void {
    this.disableButton();
  }

  nameFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
  ]);
  telephoneNumberFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3)
  ]);
  emailInputContact = '';
  messageInputContact = '';
  isDisabled = true;

  private disableButton() {
    // subscribe to value changes on required forms...
    combineLatest(
      this.nameFormControl.valueChanges,
      this.telephoneNumberFormControl.valueChanges
    ).subscribe(() => {
      if (
        // there should be no errors in the name and the telephone number
        (!this.nameFormControl.hasError('minlength') &&
        !this.nameFormControl.hasError('required')) &&
        (!this.telephoneNumberFormControl.hasError('minlength') &&
        !this.telephoneNumberFormControl.hasError('required'))
      ) {
        // no errors? -> sending a message is allowed...
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    });
  }

  onSendMessage() {
    console.log(
      'Name: ' +
        this.nameFormControl.value +
        '\n' +
        'Telefonnummer: ' +
        this.telephoneNumberFormControl.value +
        '\n' +
        'E-Mail: ' +
        this.emailInputContact.valueOf() +
        '\n' +
        'Nachricht: ' +
        this.messageInputContact.valueOf()
    );
    this.contactFormService.sendCustomerMail(
      this.nameFormControl.value,
      this.telephoneNumberFormControl.value,
      this.emailInputContact.valueOf(),
      this.messageInputContact.valueOf()
    );
    this.router.navigate(['messageSentSuccessfully']);
  }
}
