import { AchievementContent } from './model/achievement-content.model';
import { Achievement } from './model/achievement.model';

export class AchievementsService {
  private content: AchievementContent = {
      companyName: 'Polsterei Reier',
      workingTitle: 'Raumausstattermeister',
      telephoneHomeContact: '05145 6036',
      telephoneContact: '0157 874 272 67',
      mailContact: 'dirk.reier@web.de',
      achievementDescription: 'Polstern und Beziehen sämtlicher Polstermöbel wie Stühle, Eckbänke, Sessel und Sofas' +
      ' im Raum Celle',
      achievementElements: [
        new Achievement('Nachhaltig', 'Ihre alten Möbel bleiben erhalten'),
        new Achievement('Individuell', 'Sie suchen sich Ihre Stoffe aus'),
        new Achievement('Gestaltend', 'Sie können Einfluss auf die verwendeten Materialen nehmen')
      ]
  };

  getCompanyName() {
    return this.content.companyName;
  }

  getWorkingTitle() {
    return this.content.workingTitle;
  }

  getTelephoneHomeContact() {
    return this.content.telephoneHomeContact;
  }

  getTelephoneContact() {
    return this.content.telephoneContact;
  }

  getMailContact() {
    return this.content.mailContact;
  }

  getAchievementDescription() {
    return this.content.achievementDescription;
  }

  getAchievement() {
    return this.content;
  }

  getAchievementElements() {
    return this.content.achievementElements;
  }
}
