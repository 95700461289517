import { ContactContent } from './model/contact-content';
import { Email } from 'src/assets/scripts/smtp.js';

declare let Email: any;

export class ContactFormService {

  sendCustomerMail(mailFromName: string, telephoneNumber: string, emailContact: string, personalMessage: string) {
    Email.send({
      Host: 'smtp.elasticemail.com',
      Username: 'polsterei.reier@gmail.com',
      Password: '0B1BF6E1FAE5631B83264B6CF4953684EE05',
      To: 'dirk.reier@web.de',
      From: 'polsterei.reier@gmail.com',
      Subject: 'Neue Anfrage über das Kontaktformular!',
      Body: '<p>Name: ' + mailFromName + '<p><br>' +
            '<p>Telefonnummer: ' + telephoneNumber + '</p><br>' +
            '<p>E-Mail-Adresse: ' + emailContact + '</p><br>' +
            '<p>Nachricht des Interessenten: ' + personalMessage + '</p>',
    }).then((message: any) => console.log(message));
  }

}
