<div class="main-div">
  <mat-card>
    <div class="contact-formular-form">
      <p id="contactDescription">
        Teilen Sie mir Ihren individuellen Wunsch schon vorab mit. Füllen Sie
        dazu einfach die folgenden Felder aus, damit ich mich schnellstmöglich
        mit Ihnen in Kontakt setzen kann.
      </p>
    </div>
    <form class="contact-formular-form">
      <mat-form-field class="full-width">
        <mat-label>Name*</mat-label>
        <input matInput [formControl]="nameFormControl" />
        <mat-error
          *ngIf="
            nameFormControl.hasError('minlength') &&
            !nameFormControl.hasError('required')
          "
        >
          Bitte geben Sie einen gültigen Namen ein
        </mat-error>
        <mat-error *ngIf="nameFormControl.hasError('required')">
          Die Angabe eines Namens ist <strong>erforderlich</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Telefonnummer*</mat-label>
        <input type="tel" matInput [formControl]="telephoneNumberFormControl" />
        <mat-error
          *ngIf="
          telephoneNumberFormControl.hasError('minlength') &&
          !telephoneNumberFormControl.hasError('required')
          "
        >
          Bitte geben Sie eine gültige Telefonnummer ein
        </mat-error>
        <mat-error *ngIf="nameFormControl.hasError('required')">
          Die Angabe einer Telefonnummer ist <strong>erforderlich</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>E-Mail</mat-label>
        <input type="email" matInput [(ngModel)]="emailInputContact" [ngModelOptions]="{standalone: true}" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Ihre Nachricht</mat-label>
        <textarea matInput [(ngModel)]="messageInputContact" [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>
      <button mat-raised-button (click)="onSendMessage()" [disabled]=isDisabled>
        Nachricht senden
      </button>
      <p id="contactDescriptionHint">
        Hinweis: Mit dem Drücken auf "Nachricht senden" und dem damit
        verbundenen Übermitteln Ihrer persönlichen Kontaktdaten, akzeptieren Sie
        die Datenschutzhinweise dieser Webseite
        <a [routerLink]="['/data-protection-notice']"
          >(zum Datenschutzhinweis)</a
        >.
      </p>
    </form>
  </mat-card>
</div>
