import { Component, OnInit } from '@angular/core';
import { AchievementContent } from './model/achievement-content.model';
import { AchievementsService } from './achievements.service';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.css']
})
export class AchievementsComponent implements OnInit {
  achievements: AchievementContent;

  constructor(private achievementService: AchievementsService) {}

  ngOnInit() {
    this.achievements = this.achievementService.getAchievement();
    this.achievements.companyName = this.achievementService.getCompanyName();
    this.achievements.workingTitle = this.achievementService.getWorkingTitle();
    this.achievements.telephoneContact = this.achievementService.getTelephoneContact();
    this.achievements.mailContact = this.achievementService.getMailContact();
    this.achievements.achievementDescription = this.achievementService.getAchievementDescription();
    this.achievements.achievementElements = this.achievementService.getAchievementElements();
  }

}
