import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AchievementsComponent } from './achievements/achievements.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { DataProtectionNoticeComponent } from './data-protection-notice/data-protection-notice.component';
import { ImprintComponent } from './imprint/imprint.component';
import { MessageSentSuccessfullyComponent } from './message-sent-successfully/message-sent-successfully.component';


const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'home', component: AchievementsComponent},
  { path: 'contact', component: ContactFormComponent},
  { path: 'data-protection-notice', component: DataProtectionNoticeComponent},
  { path: 'imprint', component: ImprintComponent},
  { path: 'messageSentSuccessfully', component: MessageSentSuccessfullyComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
