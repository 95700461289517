<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    [ngClass]="{ hidden: !(isHandset$ | async) }"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar class="menuBar">
      Menü
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/home']">Startseite</a>
      <a mat-list-item [routerLink]="['/contact']">Kontakt</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="menuMain">
      <div [ngClass]="{ hidden: (isHandset$ | async) }">
        <a [routerLink]="['/home']">Startseite</a>
        <a [routerLink]="['/contact']">Kontakt</a>
      </div>
    </div>
      <span class="spacer"></span>
      <span id="companyName"><a [routerLink]="['/home']">Polsterei Reier</a></span>

    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
