<div class="main-div">
    <mat-card>
      <div class="companyName">
        <p-header>
          <h1>{{ achievements.companyName }}</h1>
        </p-header>
      </div>
      <div class="workingTitle">
        <h4>{{ achievements.workingTitle }}</h4>
      </div>
      <div class="telephoneContact">
        <mat-icon class="iconMiddleAlign">phone</mat-icon>
        {{ achievements.telephoneHomeContact }}
      </div>
      <div class="telephoneContact">
        <mat-icon class="iconMiddleAlign">phone_iphone</mat-icon>
        {{ achievements.telephoneContact }}
      </div>
      <div class="mailContact">
        <mat-icon class="iconMiddleAlign">email</mat-icon>
        {{ achievements.mailContact }}
      </div>
      <div class="achievementDescription">
        <h4>
          {{ achievements.achievementDescription }}
        </h4>
      </div>
      <div class="toContactFormularButton">
      <a mat-raised-button routerLink="/contact" id="toContactFormularButtonText">Zum Kontaktformular</a>
    </div>
    </mat-card>
    <mat-card id="companyAppearance">
    <div class="p-grid">
      <div class="p-col-12 p-md-6 p-lg-6" id="imageReference">
        <img src="assets/images/achievementReferenceChair_gray.png" class="center">
      </div>
      <div class="p-col-12 p-md-6 p-lg-6">
        <ul class="achievementsList">
          <li id="achievementElement">
            <strong>{{ achievements.achievementElements[0].name }}</strong><br>
            {{ achievements.achievementElements[0].description }}
          </li>
          <li id="achievementElement">
            <strong>{{ achievements.achievementElements[1].name }}</strong><br>
            {{ achievements.achievementElements[1].description }}
          </li>
          <li id="achievementElement">
            <strong>{{ achievements.achievementElements[2].name }}</strong><br>
            {{ achievements.achievementElements[2].description }}
          </li>
        </ul>
      </div>
    </div>
  </mat-card>
    <mat-card id="referenceChairs">
    <div class="p-grid">
      <div class="p-col-12 p-md-6 p-lg-6">
        <img src="assets/images/achievementChairBefore.png" class="center">
        <mat-card-content class="text-center">Vorher</mat-card-content>
      </div>
      <div class="p-col-12 p-md-6 p-lg-6">
        <img src="assets/images/achievementChairAfter.png" class="center">
        <mat-card-content class="text-center">Nachher</mat-card-content>
      </div>
    </div>
  </mat-card>
  <div class="footer">
    <p-card>
      <a [routerLink]="['/imprint']" class="imprint">Impressum</a>
      <a [routerLink]="['/data-protection-notice']" class="privacyNotice">Datenschutzhinweis</a>
    </p-card>
  </div>
</div>
