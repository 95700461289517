<div class="messageSentSuccessfull">
  <mat-card class="sucessfullContent">
    <mat-card-content>
      Vielen Dank! Ihre Nachricht wurde erfolgreich übermittelt.<br>
      Ich werde mich in Kürze mit Ihnen in Verbindung setzen.
    </mat-card-content>
  </mat-card>
  <div class="toFrontpageButton">
    <a mat-raised-button routerLink="/home" id="toFrontpageButtonText">Zur Startseite</a>
  </div>
</div>
